<template>
  <v-autocomplete
    v-if="autocomplete && visible"
    v-model="value"
    v-model:search="search"
    :label="label"
    :error-messages="meta.dirty || meta.touched ? errors : []"
    :items="items"
    item-title="description"
    item-value="placeId"
    :loading="isLoading"
    clearable
    no-filter
    return-object
  >
    <template #append-inner>
      <mtf-field-icon
        :meta="meta"
        :has-errors="hasErrors"
      />
    </template>

    <template #item="{ item, props: itemProps }">
      <v-list-item
        v-bind="itemProps"
        :title="item.raw.name"
        :subtitle="item.raw.address"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { useMatterificField, useRuntimeConfig } from '#imports';
import { Loader } from '@googlemaps/js-api-loader';
import { map, get } from 'lodash-es';
import * as geofire from 'geofire-common';
import MtfFieldIcon from '../FieldIcon.vue';

export default defineComponent({
  name: 'MtfFieldPlace',
  components: { MtfFieldIcon },
  inheritAttrs: true,
  customOptions: {},
  // ----------------
  props: {
    schema: { type: Object, required: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    visible: { type: Boolean, default: true },
    // ----------------
    countryWhitelist: { type: Array, default: () => [] }
  },
  //emits: ['update:modelValue', 'input',],
  // ----------------

  async setup(props, context) {
    const { meta, value, errors, handleBlur, validate, hasErrors } = useMatterificField(
      props.name,
      props.schema,
      context
    );

    // ----------------
    const isLoading = ref(true);

    const config = useRuntimeConfig();
    const apiKey = config.public?.googleMapsApiKey;
    if (!apiKey) {
      throw new Error('Google API Key is required for this component to work!');
    }

    const loader = new Loader({
      apiKey,
      version: 'weekly',
      libraries: ['places', 'geometry']
    });

    const google = await loader.load();
    const geocoder = new google.maps.Geocoder();
    const autocomplete = new google.maps.places.AutocompleteService();

    isLoading.value = false;
    // ----------------

    const safeValue = computed({
      get() {
        return value.value;
      },
      set(place) {
        if (place) {
          const placeId = get(place, 'placeId', place);
          if (!placeId) return;
          geocoder.geocode(
            { placeId },
            ([
              {
                geometry: { location }
              }
            ]) => {
              place.geo = {
                lat: location.lat(),
                lng: location.lng(),
                hash: geofire.geohashForLocation([location.lat(), location.lng()])
              };
              value.value = place;
            }
          );
        }
      }
    });

    // ----------------

    return {
      meta,
      value: safeValue,
      errors,
      handleBlur,
      validate,
      hasErrors,
      autocomplete,
      isLoading
    };
  },

  data() {
    return {
      items: [],
      search: null,
      selected: null
    };
  },

  watch: {
    search(val) {
      val && val !== this.value?.placeId && this.querySelections(val);
    }
  },

  methods: {
    // ensureExists() {
    //   this.suggestions ??= [];
    //   if (this.value && !this.value.length) {
    //     this.suggestions.push(this.value);
    //   }
    // },

    // get predictions from google based on the search string
    querySelections(input) {
      this.isLoading = true;

      this.autocomplete.getPlacePredictions(
        {
          input,
          componentRestrictions: { country: this.countryWhitelist }
        },
        (predictions) => {
          this.items = map(predictions, (p) => ({
            description: p.description,
            placeId: p.place_id,
            name: p.structured_formatting.main_text,
            address: p.structured_formatting.secondary_text
          }));

          this.isLoading = false;
        }
      );
    }
  }
});
</script>
